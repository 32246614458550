<script setup lang="ts">
  const currentYear = new Date().getFullYear()
</script>

<template>
  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        <div class="px-5 py-2">
          <router-link to="/privacy-policy" v-slot="{ route }">
            <span class="text-base text-gray-500 hover:text-gray-900">Privacy Policy</span>
          </router-link>
        </div>
      </nav>
      <p class="mt-8 text-center text-base text-gray-400">&copy; {{ currentYear }} Chatelj Most.</p>
    </div>
  </footer>
</template>
