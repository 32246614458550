<script setup lang="ts">
  import { CloudUploadIcon, LockClosedIcon, RefreshIcon } from '@heroicons/vue/outline'
  import { ref } from 'vue'
  import axios from 'axios'
  import { Form, Field, ErrorMessage } from 'vee-validate'

  document.title = 'Chatelj Most - Services'

  const features = [
    { name: 'Bringing value to relations', icon: CloudUploadIcon, description: 'Our core value is to add something valuable to the environment around us. Be it personal or business value.' },
    {
      name: 'Connecting the right people',
      icon: RefreshIcon,
      description: 'New ideas and relations are born when the right people meet at the right time. Our aim to provide the right conditions for the right people.',
    },
    { name: 'Never compromising security', icon: LockClosedIcon, description: 'One of our main principle is security. Providing a high level security, our clients can trust their data with us.' },
  ]

  const isFormSubmitted = ref(false)
  const name = ref()
  const email = ref()
  const subject = ref()
  const message = ref()

  function validateEmptyField(value: any) {
    if (!value) {
      return 'This field is required'
    }

    return true
  }

  function validateEmail(value: any) {
    if (!value) {
      return 'This field is required'
    }

    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!regex.test(value)) {
      return 'This field must be a valid email'
    }

    return true
  }

  async function submitForm() {
    try {
      await axios({
        method: 'POST',
        url: 'https://wt.pstbck.net/items/placeholder_sites',
        data: {
          name: name.value,
          email: email.value,
          subject: subject.value,
          message: message.value,
          source: window.location.hostname,
        },
      })
      isFormSubmitted.value = true
    } catch (error) {
      console.log(error)
    }
  }
</script>

<template>
  <div class="relative bg-white py-16">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">Services</p>

      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ feature.name }}</h3>
                <p class="mt-5 text-base text-gray-500">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full">
    <div class="bg-gradient-to-b from-indigo-500 to-indigo-100 h-96"></div>
    <div class="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8 mb-12">
      <div v-if="!isFormSubmitted" class="bg-white w-full shadow rounded p-8 sm:p-12 -mt-72">
        <p class="text-3xl font-bold leading-7 text-center">Contact us</p>
        <Form @submit="submitForm">
          <div class="md:flex items-center mt-12">
            <div class="w-full flex flex-col">
              <label class="font-semibold leading-none">Name</label>
              <Field
                name="name"
                type="text"
                v-model="name"
                :rules="validateEmptyField"
                class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
              />
              <ErrorMessage name="name" class="text-red-400 font-semibold" />
            </div>
          </div>
          <div class="md:flex items-center mt-12">
            <div class="w-full flex flex-col">
              <label class="font-semibold leading-none">Email</label>
              <Field
                name="email"
                type="email"
                v-model="email"
                :rules="validateEmail"
                class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
              />
              <ErrorMessage name="email" class="text-red-400 font-semibold" />
            </div>
          </div>
          <div class="md:flex items-center mt-8">
            <div class="w-full flex flex-col">
              <label class="font-semibold leading-none">Subject</label>
              <Field
                name="subject"
                type="text"
                v-model="subject"
                :rules="validateEmptyField"
                class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
              />
              <ErrorMessage name="subject" class="text-red-400 font-semibold" />
            </div>
          </div>
          <div>
            <div class="w-full flex flex-col mt-8">
              <label class="font-semibold leading-none">Message</label>
              <Field
                as="textarea"
                name="message"
                v-model="message"
                class="h-40 p-3 leading-none text-gray-900 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
                :rules="validateEmptyField"
              >
                <textarea name="message" id="" cols="30" rows="10"></textarea>
              </Field>
              <ErrorMessage name="message" class="text-red-400 font-semibold" />
            </div>
          </div>
          <div class="flex items-center justify-center w-full">
            <button class="mt-9 font-semibold leading-none text-white py-4 px-10 bg-indigo-500 rounded hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:outline-none">
              Send message
            </button>
          </div>
        </Form>
      </div>
      <div v-if="isFormSubmitted" class="bg-white w-full shadow rounded p-8 sm:p-12 -mt-72">
        <h1 class="text-center font-semibold text-xl">Thank you for your email.</h1>
      </div>
    </div>
  </div>
</template>
